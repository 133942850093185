/// <reference path="importer.ts" />

$(document).ready(function() {
    $(document).on('init-faux', 'select', function() {
        var select = $(this);
        var faux = $(this).parent();
        var val = faux.find('.value');

        select.change(function() {
            val.text(select.find('option:selected').text());
        }).change();
    });

    //auto-init pre-existing faux
    $('.select-faux select').trigger('init-faux');
});